import './styles/pet-assistant.css'
import { AaronImg, AlfredHappyImg, AlfredNeutralImg, DefUsPic, KevinImg, VickyImg } from '../assets/images';

/** Renders a pet assistant compo.
 * @param {Object} props
 * @param {boolean} [props.animOnDwarf] Animates the message when screen width is
 * less or equal to 500px. Use this when the message is too large to fit in the
 * message box.
 * @param {'kevin'|'aaron'|'vicky'|'alfred-1'|'alfred-2'} [props.pet] The name of
 * the pet to be rendered in the image place. Default user picture is the default.
 * @param {string} props.message The message to be displayed.
 */
const PetAssistant = props => {
  const getClassName = () => {
    let pClass = 'pet-assistant';

    if (props.animOnDwarf) pClass += ' anim-dwarf';

    return pClass;
  }

  const getPet = () => {
    switch (props.pet) {
      case 'aaron': return AaronImg;
      case 'alfred-1': return AlfredHappyImg;
      case 'alfred-2': return AlfredNeutralImg;
      case 'vicky': return VickyImg;
      case 'kevin': return KevinImg;
      default: return DefUsPic;
    }
  }

  return (
    <div className={getClassName()}>
      <div className="pet-img-container">
        <img src={getPet()} alt="assistant" className={props.pet} />
      </div>
      <div className="message-container">
        <p className="overset p-1">{props.message}</p>
        <p className="overset p-2">{props.message}</p>
      </div>
    </div>

  );
}

export default PetAssistant;