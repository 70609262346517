import { createContext } from "react";

// *** Typedefs ***
/** AlertMessageObject Type Definition.
 * @typedef {Object} EmergentMessageObject The AlertMessage and MessageHint object.
 * @property {string} message The message to be displayed.
 * @property {'error'|'warning'|'complete'} [type] Message type.
 */

/** CacheFileObject typedef
 * @typedef {Object} ChacheFileObject
 * @property {Blob|File|string} content File's content. Can be a Blob or a dataURL.
 * @property {string} name Name or pathname of the file.
 * @property {number} [size] The file's size.
 * @property {number} [timestamp] The file's timestamp.
 */

/** CurrentSessionObject typedef
 * @typedef {Object} CurrentSessionObject
 * @property {number} creationDate The current session's user creation.
 * @property {number} id The current session's user id.
 * @property {boolean} isSubuser Determines if current sessions belongs to an subuser.
 * @property {1|2|3} [sessionStatus] The current session status, defined by User class globals, if undefined,
 * sessionStatus has not been fetched.
 * @property {string} tst The current session's TST.
 * @property {string} username The current session's username.
 */

/** SearchMethodObject typedef
 * @typedef {Object} SearchMethodObject
 * @property {(input?: string) => void} action A callback function when compo is actioned (button is
 * pressed or input is submited).
 * @property {*} [icon] An icon for the compo (only valid when type is 'button').
 * @property {string} [placeholder] The placeholder for the compo.
 * @property {'button'|'input'} type The button type. If undefined, compo won't be rendered.
 * @property {string} [value] Only valid when type is 'input'.
 */

/** globalContext Type Definition.
 * @typedef GlobalContext
 * @property {CurrentSessionObject} currSession The current session object. You
 * can get TST, sessionStatus and more from this object.
 * @property {*} currSessionPicture The current session user picture (dataURL).
 * @property {(name: string) => ChacheFileObject|undefined} getCacheFile Obtains
 * a cached file from cached files array by name.
 * @property {(m: EmergentMessageObject) => void} pushAlertMessage A function to push a new object to
 * the AlertMessage Compo's queue.
 * @property {(file: ChacheFileObject) => void} pushCacheFile Pushes a new file
 * to the cacheFiles array. The file's name must be unique. If another file with the same name is found,
 * the existent file's info will be replaced with the new one's. If its name or content is undefined,
 * new file will be discarded. This pushes the file into primary memory, not into local storage. Might
 * throw an error since this function manipulates the primary memory.
 * @property {(m: EmergentMessageObject) => void} pushMessageHint A function to push a new object to
 * the MessageHint Compo's list.
 * @property {(name: string) => void} removeCacheFile Removes a cached file from cached files array by name.
 * @property {SearchMethodObject} searchMethod A useState value used by search
 * bar from Navbar Compo.
 * @property {(obj: CurrentSessionObject) => void} setCurrSession Assings
 * the current session object to state.
 * @property {(pic: *) => void} setCurrSessionPicture Assings a new user picture. Ccall this function
 * right after user session has been comfirmed and its picture is fetched.
 * @property {(sM?: SearchMethodObject) => void} setSearchMethod A setStateAction
 * function to set a search method (button or input) from global Navbar Compo.
 * @property {(sF: boolean) => void} setShowFooter A setStateAction function to show or hide page footer.
 * @property {(sLS: boolean) => void} setShowLoadingScreen A setStateAction function to show or hide global
 * LoadingScreen Compo
 * @property {(sSL: boolean) => void} setShowLogin A setStateAction function to show or hide global Login Compo.
 * @property {(sSPT?: 'PRIV'|'TERM'|'') => void} setShowPrivacyAndTerms A setStateAction function to show or
 * hide Privacy and Terms popup.
 * @property {(sSPM: boolean) => void} setShowProfileMenu A setStateAction function to show or hide global
 * ProfileMenu Compo.
 * @property {(sSSS: boolean) => void} setShowSplashScreen A setStateAction function to show or hide global
 * SplashScreen compo.
 * @property {(sTO: number) => void} setTimezoneOffset A setStateAction function for timezone offset from
 * database.
 * @property {boolean} showLogin A useState value used by Login Compo.
 * @property {boolean} showProfileMenu A useState value used by ProfileMenu Compo.
 * @property {boolean} showSplashScreen A useState value used for splash screen display.
 * @property {number} timezoneOffset A useState value that stores timezone offset from database (in millis).
 */

/** @type {React.Context<GlobalContext>} */
export const globalContext = createContext();
