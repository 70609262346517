import "./styles/email-management.css";
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from './context/GlobalContext';
import { useNavigate, useParams } from "react-router-dom";
import Button from "./components/Button";
import LoadingPanel from "./components/LoadingPanel";
import DAOServ from "./objects/DAOServ";
import ErrHandler from "./objects/ErrHandler";
import { AlfredNeutralImg, KevinImg, HomeIcon } from "./assets/images";

/** Renders a EmailManagement compo.
 * @param {Object} props
 * @param {0|1} props.mode Sets the mode for the page. If 0, mode is verification mode, if 1, mode
 * is restoration mode.
 */
const EmailManagement = props => {
  // *** useContext ***
  const { pushMessageHint, setSearchMethod } = useContext(globalContext);
  // *** useEffect ***
  const [emailStatus, setEmailStatus] = useState(/** @type {-1|1|2} */(undefined));
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useParams ***
  const params = useParams();
  // *** useRef ***
  const emailCode = useRef(/** @type {0|1} */(params['emailCode']));

  // WARNING! Since useEffect updates twice in dev mode, response given would show
  // as the email couldn't be verified, but it was verified in the first update. In
  // production this shouldn't happen.
  useEffect(() => {
    const workEmail = () => {
      const location = props.mode === 0
        ? 'verify_email'
        : 'restore_email';

      DAOServ.post(location, { emailCode: emailCode.current }, 'JSON')
        .then(() => setEmailStatus(props.mode === 0 ? 1 : 2))
        .catch(err => {
          if (ErrHandler.getCode(err) !== ErrHandler.CODES.NOT_FOUND)
            pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });

          setEmailStatus(-1);
        });
    }

    setSearchMethod();
    workEmail();
  }, [props.mode, pushMessageHint, setSearchMethod]);

  return (<div className="container email-management">
    {/* Email is being verified or restored. */}
    {!emailStatus && <LoadingPanel height="100%" />}
    {emailStatus !== undefined && <div className="email-worked">
      <div className="img-container">
        <img className={emailStatus === -1 ? 'alfred' : 'kevin'} src={emailStatus === -1 ? AlfredNeutralImg : KevinImg} alt="verification" />
      </div>
      {emailStatus !== -1 && <h2 className="highlight">{emailStatus === 1
        ? '¡El correo fue verificado exitosamente!'
        : 'El correo fue restaurado'}
      </h2>}
      {emailStatus === -1 && <h4 className="overset">
        No pudimos encontrar el código proporcionado.
      </h4>}
      <Button empty
        icon={HomeIcon}
        onClick={() => navigate('/', { replace: true })}
        value="Regresar a inicio" />
    </div>}
  </div>);
}

export default EmailManagement;