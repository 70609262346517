import './styles/mainpage.css';
import { useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { globalContext } from "./context/GlobalContext";
import Button from "./components/Button";
import Global from "./objects/Global";
import UIRender from "./objects/UIRender";
import User from "./objects/User";
import * as Icons from './assets/images/index';

const MainPage = () => {
  // *** useContext ***
  const { currSession, setSearchMethod, setShowLogin, showSplashScreen } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const c1 = useRef(/** @type {HTMLSpanElement} */(undefined)); // Logo's curtain 1.
  const c2 = useRef(/** @type {HTMLSpanElement} */(undefined)); // Logo's curtain 2.
  const cuntratLogo = useRef(/** @type {HTMLHeadingElement} */(undefined)); // Cuntrat.
  const contratWord = useRef(/** @type {HTMLHeadingElement} */(undefined)); // Contract.

  /** @type {React.MouseEventHandler<HTMLAnchorElement>} */
  const contactAnchorClickHandler = e => {
    e.preventDefault();
    navigate(Global.PATH_CONTACT_US);
  }

  // Showing search bar.
  useEffect(() => {
    setSearchMethod({
      action: () => navigate(Global.PATH_SEARCH),
      type: 'button',
      icon: Icons.MapIcon,
      placeholder: 'Buscar propiedades'
    });
  }, [navigate, setSearchMethod]);

  // Checks user data when page has been loaded completely.
  useEffect(() => {
    if (currSession.sessionStatus === User.SESSION_ACT)
      navigate(Global.PATH_HOME, { replace: true });
    else
      UIRender.scrollTo();
  }, [currSession, navigate]);

  useEffect(() => {
    if (!showSplashScreen) { // Do all the animation magic.
      c1.current.classList.add('anim');
      c2.current.classList.add('anim');
      contratWord.current.classList.add('anim');
      cuntratLogo.current.classList.add('anim');
    }
  }, [showSplashScreen])

  return (<div className="main-page">
    <div className="main-background-container">
      <img src={Icons.MainBackgroundImg} alt="background" className="main-background" />
    </div>
    <div className="main-container">
      <div className="pet-container">
        <img src={Icons.KevinImg} alt="kevin-coon" className="pet" />
      </div>
      <div className="card-box logo-container">
        <h4><span className="highlight">F</span>irma un</h4>
        <div className="logo">
          <h1 className="contract" ref={contratWord}><span className="highlight">C</span>ontrato</h1>
          <h1 className='cuntrat' ref={cuntratLogo}>
            <span id='s1'>C</span>
            <span id='s2'>u</span>
            <span id='s3'>n</span>
            <span id='s4'>t</span>
            <span id='s5'>r</span>
            <span id='s6'>a</span>
            <span id='s7'>t</span>
          </h1>
          <span className="curt c1" ref={c1} />
          <span className="curt c2" ref={c2} />
        </div>
        <div className="flex-box jc-center m3 button-container">
          <Button animated
            empty
            icon={Icons.MapIcon}
            onClick={() => navigate(Global.PATH_SEARCH)}
            rounded={{ bL: true, tL: true }}
            value='Buscar propiedades' />
          <Button animated
            empty
            icon={Icons.LoginIcon}
            onClick={() => setShowLogin(true)}
            rounded={{ bR: true, tR: true }}
            value='Iniciar sesión' />
        </div>
      </div>
      <div className="about-page-container">
        <div className="card-box">
          <h2>
            <span className="highlight">C</span>ubrimos <span className="highlight">toda la república</span>.
          </h2>
          <div className="message-container">
            <img src={Icons.MxRepublicImg} alt="mx-republic" />
            <p>
              <span className="highlight">U</span>tilizamos <span className="highlight">Mapas de Google </span>
              para que otras personas puedan ubicar tus propiedades lo más fácil posible.
            </p>
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">D</span>esde terrenos hasta grandes complejos.</h2>
          <div className="message-container">
            <p>
              <span className="highlight">P</span>romociona tu propiedad, no importa el tipo ni el estado
              en el que se encuentre.
            </p>
            <img src={Icons.TerrainImg} alt="terrain" />
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">F</span>irma contratos digitales en tiempo real.</h2>
          <div className="message-container">
            <img src={Icons.LiveContractImg} alt="live-contract" />
            <p>
              <span className="highlight">A</span>bre una sala y envía el código de invitación a tu
              arrendatario para poder firmar un contrato.
            </p>
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">H</span>az tus contratos como quieras.</h2>
          <div className="message-container">
            <p>
              <span className="highlight">E</span>scoge cuánto tiempo quieres que dure un contrato y
              cada cuanto tiempo recibir el pago de arrendamiento.
            </p>
            <img src={Icons.StackedContractsImg} alt="stacked-contracts" />
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">T</span>us contratos en un solo sitio.</h2>
          <div className="message-container">
            <img src={Icons.KevinArchiveImg} alt="kevin-archive" />
            <p>
              <span className="highlight">A</span>bre una sala y envía el código de invitación a tu
              arrendatario para poder firmar un contrato.
            </p>
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">C</span>ontratos seguros.</h2>
          <div className="message-container">
            <p>
              <span className="highlight">C</span>on <span className="highlight">Blockchain</span>, tus
              contratos se almacenan en nuestro servidor y nadie podrá modificarlos.
            </p>
            <img src={Icons.BlockchainImg} alt="blockchain" />
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">¡</span>Alfred te avisa!</h2>
          <div className="message-container">
            <img src={Icons.AlfredHappyImg} alt="alfred-bear" />
            <p>
              <span className="highlight">N</span>osotros te avisamos cuando un contrato esté en periodo
              de pago y cuando esté por finalizar.
            </p>
          </div>
        </div>
        <div className="card-box">
          <h2><span className="required" /><span className="highlight">¡</span>Subástala!</h2>
          <div className="message-container">
            <p><span className="highlight">S</span>ubasta tu propiedad y ofrécela al mejor postor.</p>
            <img src={Icons.MazeImg} alt="maze" />
          </div>
        </div>
        <div className="card-box">
          <h2>
            <span className="required" /><span className="highlight">G</span>estión empresarial.
          </h2>
          <div className="message-container">
            <img src={Icons.AaronImg} alt="aaron-gator" />
            <p>
              <span className="highlight">T</span>e ofrecemos un gestor multi usuario para trabajar
              con tu empresa inmobiliaria, de bienes raices u hotelera.
            </p>
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">A</span>umenta tus posibilidades</h2>
          <div className="message-container">
            <p><span className="highlight">T</span>enemos varios planes de suscripción para aumentar tus
              beneficios. Si no se ajustan a ti, puedes solicitar una cotización.</p>
            <img src={Icons.NoDataImg} alt="pumpkin-img" />
          </div>
        </div>
        <div className="card-box">
          <h2><span className="highlight">¿</span>Tienes dudas?</h2>
          <div className="message-container">
            <img src={Icons.LukaSupportImg} alt="luka-horseman" />
            <p>
              <Link onClick={contactAnchorClickHandler}><span className="highlight">¡</span>Contáctanos!</Link>
              Estaremos encantados de atenderte.</p>
          </div>
        </div>
        <div className="card-box">
          <h6 className="overset">
            Las secciones con <span className="required" />requieren de una licencia de pago para funcionar.
          </h6>
        </div>
      </div>
    </div>
  </div>);
}

export default MainPage;