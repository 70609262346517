import '../styles/login.css'
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import DAOServ from '../objects/DAOServ';
import ErrHandler from '../objects/ErrHandler';
import Hintbox from '../components/Hintbox';
import Inputbar from '../components/Inputbar';
import Global from '../objects/Global';
import UIRender from '../objects/UIRender';
import User from '../objects/User';
// Icons
import * as Icons from '../assets/images';

/** UserDataObject typedef
 * @typedef {Object} UserDataObject
 * @property {string} password Password input.
 * @property {string} username Username input.
 */

/** Renders a Login compo.
 * @param {Object} props The props object.
 * @param {() => void} props.onHide A callback function that will be called when
 * popup is hidden.
 */
const Login = props => {
  // *** useContext ***
  const { currSessionPicture, pushMessageHint } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const custJump = useRef(/** @type {number} */(undefined));
  const popupContentRef = useRef(/** @type {HTMLDivElement} */(undefined));
  const popupRef = useRef(/** @type {HTMLDivElement} */(undefined));
  const popupId = useRef('login-popup');
  const recoveryInput = useRef(/** @type {string} */(undefined));
  const recoveryResponse = useRef({ email: '', code: '', passcode: '', username: '' });
  const recoveryLinkRef = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const signupLinkRef = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const userData = useRef(/** @type {UserDataObject} */({}));
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [location, setLocation] = useState(/** @type {0|1|2|3|4|5|6} */(0));
  const [togglePassword, setTogglePassword] = useState(true);

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const contentOnAnimationEndHandler = e => {
    e.stopPropagation();

    if (popupContentRef.current.classList.contains('anim-dispose')) { // Popup location change.
      const cL = popupContentRef.current.classList;
      const dir = cL.contains('next') ? 1 : -1;
      setLocation(custJump.current >= 0 ? custJump.current : location + dir);

      if (custJump.current >= 0) custJump.current = undefined;
    } else { // Popup showed.
      popupContentRef.current.classList.remove('anim-show');
      popupContentRef.current?.classList.remove('first-render');
    }
  }

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const dispose = e => {
    if (e.target === popupRef.current && UIRender.isHidden(popupRef.current))  // Dispose popup.
      props.onHide();
  }

  /** @type {React.KeyboardEventHandler<HTMLInputElement>} */
  const formHandleOnKeyPress = e => {
    if (e.target.value && e.key === 'Enter') {
      const formElms = Global.getElements('.login-form-element');

      if (formElms.findIndex(elem => elem === e.target) === 0)
        UIRender.setFocus(formElms.at(1));
    }
  }

  /** @type {React.MouseEventHandler<HTMLAnchorElement>} */
  const linkContainerOnClickHandler = e => {
    e.preventDefault();

    if (e.target === signupLinkRef.current) // Clicked over signup anchor.
      UIRender.hideElement(popupRef.current);
    else if (e.target === recoveryLinkRef.current) { // Clicked over recovery anchor.
      popupContentRef.current?.classList.add('anim-dispose');
      popupContentRef.current?.classList.add('next');
    }
  }

  const getPopupTitle = () => {
    switch (location) {
      case 1: return 'Recuperación';
      case 2: return 'Confirmación';
      case 3: return 'Nueva contraseña';
      case 4: return 'Usa el nombre de usuario';
      case 5: return 'Contacta a tu supervisor';
      case 6: return 'Contacta a soporte';
      default: return 'Iniciar sesión';
    }
  }

  /** @type {React.FormEventHandler<HTMLFormElement>} */
  const popupOnSubmitHandler = async e => {
    e.preventDefault(); // Form prevent default.
    setDisableUI(true); // Disabling UI.
    UIRender.blurFocus(); // Removing focus.

    // Payload.
    const payload = { username: userData.current.username, passcode: userData.current.password };
    // Request fetch data.
    await DAOServ.post('login', payload, 'JSON')
      .then(query => {
        try {
          localStorage.setItem('tst', query.tst);
          UIRender.reloadPage();
        } catch (err) {
          pushMessageHint({
            message: ErrHandler.getError(ErrHandler.CODES.BROWSER_STORAGE_FULL),
            type: 'error'
          });

          localStorage.removeItem('tst');
          setDisableUI(false);
        }
      }).catch(err => {
        let message = ErrHandler.parseError(err), type = 'error';

        if (ErrHandler.getCode(err) === ErrHandler.CODES.NOT_FOUND) {
          // User not found.
          if (`${err}`.includes('suspended')) {
            message = 'Esta cuenta fue suspendida. Contacta con soporte para más información';
            type = 'warning'
          } else if (`${err}`.includes('inactive')) {
            message = 'Esta cuenta está inactiva. Solicita a tu administrador la activación';
            type = 'warning';
          } else {
            message = 'El nombre de usuario o contraseña son incorrectos';
            type = 'error';
          }
        }

        pushMessageHint({ message, type });
        setDisableUI(false);
      });
  }

  /** Request user recovery
   * @param {string} data The info to be requested.
   */
  const requestRecoveryBtnClickHandler = () => {
    setDisableUI(true);

    if (location === 1 || location === 4) { // Login & recovery through username
      DAOServ.post('account_recovery', { data: recoveryInput.current }, 'JSON')
        .then(data => {
          popupContentRef.current.classList.add('anim-dispose');
          recoveryResponse.current.email = data['email'];
          recoveryResponse.current.username = data['username'];

          if (location === 1) popupContentRef.current.classList.add('next');
          else custJump.current = 2;
        }).catch(err => {
          const code = ErrHandler.getCode(err);
          let message = ErrHandler.parseError(err), type = 'error', pMH = false;

          switch (code) {
            case ErrHandler.CODES.FORBIDDEN: { // Account is not verified.
              popupContentRef.current.classList.add('anim-dispose');
              custJump.current = 6;
              break;
            } case ErrHandler.CODES.UNAVAILABLE: { // Account is a subaccount.
              popupContentRef.current.classList.add('anim-dispose');
              custJump.current = 5;
              break;
            } case ErrHandler.CODES.UNIQUE_VIOLATION: { // Multiple users with same email.
              popupContentRef.current.classList.add('anim-dispose');
              custJump.current = 4;
              break;
            } case ErrHandler.CODES.NOT_FOUND: { // User not found.
              message = 'El usuario no existe';
              type = 'warning';
              pMH = true;
              setDisableUI(false);
              break;
            } default: {
              pMH = true;
              setDisableUI(false);
            }
          }

          if (pMH) pushMessageHint({ message, type });
        });
    } else if (location === 2) { // Recovery through username or email
      DAOServ.post('find_recovery_code', { code: recoveryResponse.current.code }, 'JSON')
        .then(data => {
          popupContentRef.current.classList.add('anim-dispose');
          popupContentRef.current.classList.add('next');
          recoveryResponse.current.username = data['username'];
        }).catch(err => {
          const code = ErrHandler.getCode(err);
          let msg = ErrHandler.parseError(err);

          if (code === ErrHandler.CODES.NOT_FOUND)
            msg = 'No se encontró el código proporcionado';

          pushMessageHint({ message: msg, type: 'error' });

          setDisableUI(false);
        });
    } else if (location === 3) { // Account restore
      const body = { code: recoveryResponse.current.code, passcode: recoveryResponse.current.passcode };
      DAOServ.post('restore_account', body, 'JSON')
        .then(() => {
          popupContentRef.current.classList.add('anim-dispose');
          custJump.current = 0;
          pushMessageHint({ message: 'La cuenta fue restaurada', type: 'complete' });
        }).catch(err => {
          let msg = ErrHandler.parseError(err), type = 'error';
          const code = ErrHandler.getCode(err);

          if (ErrHandler.getCode(err) === ErrHandler.CODES.NOT_FOUND) {
            msg = 'El código de recuperación ha caducado';
            type = 'warning';
            custJump.current = 1;
            popupContentRef.current.classList.add('anim-dispose');
          } else if (code === ErrHandler.CODES.FORBIDDEN) {
            msg = 'La contraseña debe ser diferente a la anterior';
            type = 'warning';
          }

          pushMessageHint({ message: msg, type });
          setDisableUI(false);
        });
    }
  }

  useEffect(() => {
    const parent = popupRef.current?.parentNode;
    const options = { container: true, footer: true, navbar: true };

    UIRender.disableGlobalScroll('login-popup');
    UIRender.disableSiblings(popupRef.current, options);

    return () => {
      UIRender.enableGlobalScroll('login-popup');
      UIRender.enableChilds(parent, options, 'login-popup');
    }
  }, []);

  useEffect(() => {
    popupContentRef.current?.classList.add('anim-show');
    setDisableUI(false);

    if (location === 0) { // Login.
      setDisableSubmitBtn(!userData.current?.username || !userData.current?.password);
    } else {
      setDisableSubmitBtn(true);
    }
  }, [location])

  return (
    <div ref={popupRef} className="popup-wrapper login-popup" id={popupId.current} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="title highlight">{getPopupTitle()}</h2>
          <Button disabled={disableUI}
            id='popup-close'
            className='error empty rounded reduced borderless'
            icon={Icons.CloseIcon}
            onClick={() => UIRender.hideElement(popupRef.current)}
            rounded />
        </div>
        {location === 0 && <div ref={popupContentRef} // Login mode
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <div className="flex-box wrap">
            <div className="child">
              <img className='login-img' src={currSessionPicture} alt="" />
            </div>
            <div className="child">
              <form id='login-form' onSubmit={e => popupOnSubmitHandler(e)} onKeyDown={formHandleOnKeyPress}>
                <Inputbar stopPropagation
                  onChange={input => {
                    userData.current.username = input;
                    setDisableSubmitBtn(!userData.current.username || !userData.current.password);
                  }} className='login-form-element'
                  disabled={disableUI}
                  defaultValue={userData.current?.username}
                  filters={[{ regExp: Global.REGEXP_FILTER_USERNAME }]}
                  maxLength={User.USERNAME_MAX_LENGHT}
                  placeholder={{ default: 'Usuario' }}
                  requestFocus
                  textCenter
                  textTransform='lowercase' />
                <Inputbar stopPropagation
                  onChange={input => {
                    userData.current.password = input;
                    setDisableSubmitBtn(!userData.current.username || !userData.current.password);
                  }} className='login-form-element'
                  disabled={disableUI}
                  defaultValue={userData.current?.password}
                  filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
                  maxLength={User.PASSWORD_MAX_LENGTH}
                  placeholder={{ default: 'Contraseña' }}
                  textCenter
                  type='password' />
                <Button animated
                  fullWidth
                  disabled={disableSubmitBtn}
                  id='login-btn'
                  type='submit'
                  className='login-form-element'
                  value='Iniciar sesión'
                  onWaitValue='Solicitando...'
                  isWaiting={disableUI}
                  form='login-form'
                  icon={Icons.LoginIcon} />
              </form>
            </div>
          </div>
          <div onClick={linkContainerOnClickHandler}>
            <label>¿No tienes cuenta? <Link ref={signupLinkRef} to={Global.PATH_SIGNUP}>regístrate</Link></label>
            <br />
            <label>¿Olvidaste algo? <Link ref={recoveryLinkRef} >recupera tu cuenta</Link></label>
          </div>
        </div>}
        {location === 1 && <div ref={popupContentRef} // Recovery mode
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <div className="flex-box jc-left m3">
            <Button borderless
              empty
              icon={Icons.ArrowIcon}
              onClick={() => popupContentRef.current?.classList.add('anim-dispose')}
              reduced
              rotation={180}
              rounded
              value='Regresar' />
          </div>
          <Hintbox icon={Icons.InfoIcon}
            message={'Ingresa el nombre de usuario o el correo de la cuenta que deseas recuperar.'
              + ' Enviaremos un correo de recuperación a la última dirección verificada de la cuenta.'
            } />
          <Inputbar disabled={disableUI}
            filters={[
              { regExp: Global.REGEXP_FILTER_SYMBOLED_LETTERS },
              { regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
            isValid={input => {
              return Global.REGEXP_EMAIL.test(input)
                || (input.length >= User.USERNAME_MIN_LENGTH
                  && input.length <= User.USERNAME_MAX_LENGHT
                  && Global.REGEXP_USERNAME.test(input))
            }} maxLength={255}
            onChange={input => {
              recoveryInput.current = input;
              setDisableSubmitBtn(!recoveryInput.current);
            }} placeholder={{ default: 'Usuario o correo', onIsValidFail: 'Entrada inválida' }}
            requestFocus
            required
            type='email' />
          <div className="flex-box m5">
            <Button disabled={disableSubmitBtn}
              animated
              icon={Icons.MailIcon}
              isWaiting={disableUI}
              onClick={requestRecoveryBtnClickHandler}
              onWaitValue='Solicitando...'
              value='Solicitar' />
          </div>
        </div>}
        {location === 2 && <div ref={popupContentRef} // Recovery code verification mode
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <Hintbox icon={Icons.InfoIcon}
            message={`Enviamos un correo a '${recoveryResponse.current.email}' para recuperar a`
              + ` '${recoveryResponse.current.username}'. Revisa tu bandeja`
              + ' de entrada o tu bandeja de correo no deseado, busca el correo que te enviamos y'
              + ' copia y pega el código de cinco caracteres que recibiste en el campo solicitado.'} />
          <Inputbar disabled={disableUI}
            filters={[{ regExp: Global.REGEXP_FILTER_ALL_SYMBOLS }]}
            maxLength={5}
            minLength={5}
            onChange={input => {
              recoveryResponse.current.code = input
              setDisableSubmitBtn(!recoveryResponse.current.code);
            }} placeholder={{
              default: 'Código de recuperación',
              onIsValidFail: 'Entrada inválida',
              onMinLengthFail: 'Ingresa 5 caracteres'
            }} requestFocus
            required
            textCenter />
          <div className="flex-box m5">
            <Button disabled={disableSubmitBtn}
              animated
              icon={Icons.MailIcon}
              isWaiting={disableUI}
              onClick={requestRecoveryBtnClickHandler}
              onWaitValue='Comprobando...'
              value='Comprobar' />
          </div>
        </div>}
        {location === 3 && <div ref={popupContentRef} // Restore account mode
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <Hintbox icon={Icons.InfoIcon}
            message={'Ingresa una contraseña de 8 a 30 caracteres. Ingresa una que no vayas a'
              + ' olvidar pero que no sea fácil de adivinar. Si quieres utilizar símbolos, solo'
              + ' se permiten @#$!%?&_.'} />
          <div className="flex-box">
            <div className="child">
              <Inputbar onChange={input => {
                recoveryResponse.current.passcode = input;
                setDisableSubmitBtn(!Boolean(recoveryResponse.current.passcode));
              }} disabled={disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
                isValid={input => !Global.REGEXP_RACCHOME.test(input)
                  && !Global.REGEXP_CUNTRAT.test(input)
                  && !recoveryResponse.current.username.includes(input)}
                maxLength={User.PASSWORD_MAX_LENGTH}
                minLength={User.PASSWORD_MIN_LENGTH}
                placeholder={{
                  default: 'Contraseña nueva',
                  onIsValidFail: 'Entrada inválida: no uses tu usuario ni símbolos prohibidos',
                  onMinLengthFail: 'La contraseña debe tener de 8 a 30 caracteres'
                }} required
                type={togglePassword ? 'password' : 'text'} />
            </div>
            <div className="child auto-width m5">
              <Button empty
                icon={togglePassword ? Icons.ShowIcon : Icons.HideIcon}
                onClick={() => setTogglePassword(!togglePassword)}
                reduced
                rounded />
            </div>
          </div>
          <div className="flex-box m5">
            <Button disabled={disableSubmitBtn}
              animated
              icon={Icons.SaveIcon}
              isWaiting={disableUI}
              onClick={requestRecoveryBtnClickHandler}
              onWaitValue='Guardando...'
              value='Guardar' />
          </div>
        </div>}
        {location === 4 && <div ref={popupContentRef} // Recovery mode (username only)
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <div className="flex-box jc-left m3">
            <Button borderless
              empty
              icon={Icons.ArrowIcon}
              onClick={() => {
                popupContentRef.current?.classList.add('anim-dispose');
                custJump.current = 0;
              }} reduced
              rotation={180}
              rounded
              value='Regresar' />
          </div>
          <Hintbox icon={Icons.InfoIcon}
            message={'Hay más de un usuario ligado a esta dirección. Escribe el nombre de usuario'
              + ' que quieres recuperar.'
            } type='warning' />
          <Inputbar disabled={disableUI}
            filters={[{ regExp: Global.REGEXP_FILTER_USERNAME }]}
            isValid={input => Global.REGEXP_USERNAME.test(input)}
            maxLength={User.USERNAME_MAX_LENGHT}
            minLength={User.USERNAME_MIN_LENGTH}
            onChange={input => {
              recoveryInput.current = input;
              setDisableSubmitBtn(!recoveryInput.current);
            }} placeholder={{ default: 'Usuario', onIsValidFail: 'Entrada inválida' }}
            requestFocus
            required />
          <div className="flex-box m5">
            <Button disabled={disableSubmitBtn}
              animated
              icon={Icons.MailIcon}
              isWaiting={disableUI}
              onClick={requestRecoveryBtnClickHandler}
              onWaitValue='Solicitando...'
              value='Solicitar' />
          </div>
        </div>}
        {location === 5 && <div ref={popupContentRef} // Unavailable recovery mode
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <b className='title'>No podemos recuperar esta cuenta. :(</b>
          <p className="note">
            Esta cuenta está siendo gestionada por un administrador de terceros.
            Contacta con el administrador para poder restaurarla.
          </p>
          <Button empty
            onClick={() => {
              popupContentRef.current?.classList.add('anim-dispose');
              custJump.current = 0;
            }} rounded
            value='Aceptar' />
        </div>}
        {location === 6 && <div ref={popupContentRef} // Account not verified mode
          className="popup-content login first-render"
          onAnimationEnd={contentOnAnimationEndHandler}>
          <b className='title'>Esta cuenta no está verificada. :(</b>
          <p className="note">
            Aún no se ha verificado el correo de esta cuenta. Revisa la bandeja de entrada o la
            bandeja de correo no deseado del correo y verifícala para poder recuperarla.
            Si ya no tienes acceso a ese correo, contacta a soporte para poder brindarte más ayuda.
          </p>
          <div className="flex-box m3 jc-center">
            <div className="child auto-width">
              <Button empty
                onClick={() => {
                  popupContentRef.current?.classList.add('anim-dispose');
                  custJump.current = 0;
                }} rounded
                value='Aceptar' />
            </div>
            <div className="child auto-width">
              <Button empty
                onClick={() => {
                  UIRender.hideElement(popupRef.current);
                  navigate(Global.PATH_CONTACT_US);
                }} rounded
                value='Contactar a soporte' />
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}


export default Login;