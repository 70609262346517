import './styles/transaction-linetime.css'
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext';
import Button from './Button';
import ErrHandler from '../objects/ErrHandler';
import Global from '../objects/Global';
import Hintbox from './Hintbox';
import NotFoundBox from './NotFoundBox';
import PropDisplay from './PropDisplay';
import Transaction from '../objects/Transaction';
import UserCard from './UserCard';
import { CheckIcon, CloseIcon, CopyIcon, InfoIcon, KevinNotFoundImg, PayIcon } from '../assets/images';
import Agreement from '../objects/Agreement';
import DAOServ from '../objects/DAOServ';
import User from '../objects/User';
import Contract from '../objects/Contract';

/** AgreementPeriodObject typedef.
 * @typedef {Object} AgreementPeriodObject
 * @property {number} amount The period amount.
 * @property {number} from Start of period.
 * @property {number} period Zero-based period number.
 * @property {number} to End of period (or limit date).
 * @property {Transaction} [transaction] The transaction (payment) attached. It is undefined
 * when period hasn't been paid.
 */

/** Renders a TransactionsLineTime compo
 * @param {Object} props
 * @param {import('../objects/Contract').default} props.contract The contract attached. Its agreement
 * attribute must be defined.
 * @param {(aPO: AgreementPeriodObject) => void} props.onPay
 * A callback function that will be trigger when 'Pay' or 'Mark as paid' button is pushed.
 * @param {'lessee'|'owner'} [props.role] The current session's role. If undefined, current session
 * is inactive or is not the owner nor lessee.
 * @param {number} props.today The current day in millis.
 */
const TransactionLineTime = props => {
  // *** useContext ***
  const { currSession, pushAlertMessage, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const hash = useRef(props.contract?.getAgreement()?.getHash());
  // *** useState ***
  const [currTr, setCurrTr] = useState(/** @type {AgreementPeriodObject} */(undefined));
  const [todayWithTime, setTodayWithTime] = useState();
  const [transactions, setTransactions] = useState(
    /** @type {{ current: *, period: AgreementPeriodObject }[]} */(undefined)
  );

  const allPeriodsPaid = () => !Boolean(transactions.find(t => t.period.transaction === undefined));

  const copyToClipboard = input => {
    Global.copyToClipboard(input)
      .then(() => pushAlertMessage({ message: 'Copiado al portapapeles', type: 'complete' }))
      .catch(err => pushAlertMessage({ message: ErrHandler.parseError(err), type: 'error' }));
  }

  const disablePayButton = () => {
    if (props.role !== 'owner') return true;

    const currTrIdx = transactions.findIndex(t => t.period === currTr);
    const statusClass = props.contract.getAgreement().statusToClass();
    const invalidStatus = statusClass !== 'cut-near'
      && statusClass !== 'cut-today'
      && statusClass !== 'delayed';

    return currTrIdx === -1
      || (currTrIdx > 0 && (transactions[currTrIdx - 1].period.transaction === undefined || invalidStatus))
      || (currTrIdx === 0 && invalidStatus);
  }

  /** @type {React.MouseEventHandler<HTMLSpanElement>} */
  const dotClickHandler = e => { setCurrTr(transactions.find(t => t.current === e.target)?.period) }

  const getCurrentTransactionCutDayDate = () => {
    const payFreq = props.contract.getPayFrequency();

    if (payFreq === Contract.PAY_FREQ_UNIQUE || payFreq === Contract.PAY_FREQ_DAILY)
      return Global.parseDateUTC(currTr.from, timezoneOffset);
    else
      return Global.parseDateUTC(currTr.to, timezoneOffset)
  }

  const getCurrentTransactionPaymentType = () => {
    switch (currTr.transaction.getMethod()) {
      case Transaction.METHOD_CASH: return 'Efectivo';
      case Transaction.METHOD_INAPP: return 'Servicio de Cuntrat';
      case Transaction.METHOD_TRANSFER: return 'Transferencia bancaria';
      case Transaction.METHOD_OTHER: return 'Otro';
      default: return 'Desconocido';
    }
  }

  const getCurrentTransactionPaymentStatus = () => {
    const limitDate = props.contract.getPayFrequency() === Contract.PAY_FREQ_DAILY
      || props.contract.getPayFrequency() === Contract.PAY_FREQ_UNIQUE
      ? currTr.from
      : currTr.to;

    return currTr.transaction.getTimestamp() <= limitDate
      ? 'Pago puntual' : 'Pago tardío'
  }

  const getCurrentTransactionRef = () => {
    const type = currTr.transaction.getMethod();

    if (type === Transaction.METHOD_CASH) return 'N/A';
    else return `${currTr.transaction.getId()}` || 'N/R';
  }

  const getTransactionDate = () => {
    const milli = currTr.transaction.getTimestamp();
    const date = Global.parseDateWithTimeUTC(milli, timezoneOffset);

    return date.substring(date.length - 7, date.length) === '(00:00)'
      ? date.substring(0, date.length - 7)
      : date;
  }

  const renderLine = () => {
    if (transactions === undefined || transactions.length === 0)
      return (<div className="linetime" />);

    /** @param {number} i */
    const getDotClass = i => {
      let clss = 'dot';
      const { period } = transactions[i]

      if (i === 0) clss += ' first';

      if (period.transaction !== undefined) {
        const timeOfPay = period.transaction.getTimestamp();
        const limitDate = props.contract.getPayFrequency() === Contract.PAY_FREQ_DAILY
          || props.contract.getPayFrequency() === Contract.PAY_FREQ_UNIQUE
          ? period.from
          : period.to;

        clss += timeOfPay <= limitDate
          ? ' complete'
          : ' warning';
      } else {
        const payFreq = props.contract.getPayFrequency();
        const cutDay = payFreq === Contract.PAY_FREQ_UNIQUE || payFreq === Contract.PAY_FREQ_DAILY
          ? period.from
          : period.to;

        if (cutDay < props.today) clss += ' error';
      }

      if (transactions[i].current === currTr) clss += ' selected';

      return clss;
    }

    const getLastDotClass = () => {
      if (Boolean(props.contract.getAgreement().getCancellationDate()))
        return 'dot final err';

      return props.today >= props.contract.getAgreement().getEndDate()
        ? allPeriodsPaid()
          ? 'dot final check'
          : 'dot final err'
        : 'dot final';
    }

    /** @param {number} i */
    const getProcessWidth = i => {
      const startDate = props.contract.getAgreement().getStartDate();
      const isValid = i >= 0 && i <= transactions.length
        && (props.today >= startDate || transactions[i]?.period.to >= props.today)
        // Previous date.
        && i > 0
        && transactions[i - 1].period.to <= props.today;

      if (!isValid)
        return 0;

      const initial = i === 0 ? startDate : transactions[i - 1].period.to;
      const tN = props.today - initial;
      const lN = (i !== transactions.length
        ? transactions[i].period.to
        : props.contract.getAgreement().getEndDate()
      ) - initial;
      const fact = tN / lN;

      return props.contract.getPayFrequency() === Contract.PAY_FREQ_DAILY || fact >= 1
        ? 100
        : Math.floor(fact * 100);
    }

    const dots = [];
    const lines = [];
    const last = transactions.length;

    for (let i = 0; i < last; i++) {
      dots.push(<span className={getDotClass(i)}
        key={`d-${i}`}
        ref={transactions[i]}
        title='Haz clic para expandir.' />);
      lines.push(<div className={`fragment${i === 0 ? ' first' : i === last - 1 ? ' last' : ''}`}
        key={`f-${i}`} >
        <span className="process" style={{ 'width': `${getProcessWidth(i + 1)}%` }} />
      </div>);
    }

    dots.push(<span className={getLastDotClass()}
      key='d-final'
      title={props.contract.getAgreement().getStatus() === Agreement.STATUS_CANCELLED
        ? 'Este contrato fue cancelado'
        : allPeriodsPaid()
          ? 'Este contrato ha finalizado'
          : 'Este contrato está activo'}>
      <img src={!Boolean(props.contract.getAgreement().getCancellationDate())
        && (props.today < props.contract.getAgreement().getEndDate() || allPeriodsPaid())
        ? CheckIcon
        : CloseIcon} alt="check" />
    </span>);

    return (<div className="linetime">
      <span className="curtain" />
      <div className="line">{lines}</div>
      <div className="dots" onClick={dotClickHandler}>{dots}</div>
    </div>);
  }

  useEffect(() => {
    hash.current = props.contract?.getAgreement()?.getHash();
    setTransactions();
    setCurrTr();
  }, [props.contract]);

  useEffect(() => {
    const getTodayWithTime = async () => {
      setTodayWithTime((await DAOServ.getCurrentDay(true)));
      getTransactions();
    }

    const getTransactions = () => {
      const body = { tst: currSession.tst, hash: hash.current };

      DAOServ.post('get_agreement_calendar', body, 'JSON')
        .then(data => {
          /** @type {{ current: *, period: AgreementPeriodObject }[]} */
          const auxArr = [];

          data['periods'].forEach(p => {
            auxArr.push({
              current: {},
              period: {
                amount: p['amount'],
                from: p['from'],
                period: p['period'],
                to: p['to']
              }
            });

            if (p['transaction']) {
              const auxT = new Transaction();
              auxT.setAmount(p['transaction']['amount']);
              auxT.setCreationDate(p['transaction']['creation_date']);
              auxT.setId(p['transaction']['reference']);
              auxT.setMethod(p['transaction']['method']);
              auxT.setPayer(new User({
                id: p['transaction']['idpayer'],
                username: p['transaction']['username_payer']
              }));
              auxT.setTimestamp(p['transaction']['timestamp_date'] || auxT.getCreationDate());

              auxArr[auxArr.length - 1].period.transaction = auxT;
            }
          });

          setTransactions(auxArr);
        }).catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));
    }

    if (currSession.sessionStatus) {
      if (transactions === undefined
        && currSession.sessionStatus === User.SESSION_ACT
        && hash.current !== undefined) {
        getTodayWithTime();
      }
    }
  }, [currSession, transactions, pushMessageHint]);

  return (<div className={`transaction-linetime${transactions === undefined ? ' wait' : ''}`}>
    {(transactions === undefined || todayWithTime === undefined) && <span className="wait-curtain" />}
    {transactions?.length === 0 && <NotFoundBox img={KevinNotFoundImg}
      message='No hay transacciones' />}
    {transactions?.length > 0 && <div className="line-container">
      {renderLine()}
      <div className="transaction-container">
        {currTr === undefined && <div className="empty-transaction">
          Cada círculo representa un corte en el contrato. Haz clic sobre
          uno de ellos para ver más información.
        </div>}
        {currTr !== undefined && <div className="transaction">
          <div className="top-bar">
            <h5 className="highlight">Periodo {currTr.period + 1}</h5>
            <Button borderless
              empty
              icon={CloseIcon}
              onClick={() => setCurrTr()}
              reduced
              rounded
              stopPropagation
              typeRender='error' />
          </div>
          <div className="info-container">
            {currTr.transaction === undefined && <div className="transaction-pending">
              <PropDisplay header='Fecha de corte'
                property={getCurrentTransactionCutDayDate()} />
              <PropDisplay header='Monto'
                property={`$ ${Global.formatNumber(currTr.amount)} MXN`} />
              {props.role !== undefined && <div className="flex-box">
                <div className="child auto-width m5">
                  <Button disabled={disablePayButton()}
                    empty
                    icon={PayIcon}
                    onClick={() => props.onPay(currTr)}
                    reduced
                    rounded
                    value={props.role === 'lessee' ? 'Pagar' : 'Marcar como pagado'} /></div>
                {((props.role === 'owner'
                  && props.contract.getAgreement().getStatus() === Agreement.STATUS_ACTIVE)
                  || props.role === 'lessee') && <div className="child m5">
                    <Hintbox icon={InfoIcon}
                      message={props.role === 'lessee' ? 'Pronto podrás pagar a través de nuestra página.'
                        + ' Por ahora, realiza el pago mediante otro medio y avisa a tu arrendador.'
                        : 'Podrás registrar el pago manualmente cuando esté en pronto corte.'} />
                  </div>
                }
              </div>}
            </div>}
            {currTr.transaction !== undefined && <div className="transaction-paid">
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Estado del pago'
                    property={getCurrentTransactionPaymentStatus()} />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Monto'
                    property={`$ ${Global.formatNumber(currTr.transaction.getAmount())} MXN`} />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Fecha de la operación'
                    property={getTransactionDate()} />
                </div>
                <div className="child m3 auto-width">
                  <Button borderless
                    empty
                    icon={CopyIcon}
                    onClick={() => {
                      copyToClipboard(Global.parseDateWithTimeUTC(currTr.transaction.getTimestamp()))
                    }} reduced
                    rounded />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Método'
                    property={getCurrentTransactionPaymentType()} />
                </div>
              </div>
              <div className="flex-box">
                <div className="child m3">
                  <PropDisplay header='Folio o referencia'
                    property={getCurrentTransactionRef()} />
                </div>
                <div className="child m3 auto-width">
                  <Button borderless
                    disabled={currTr.transaction.getMethod() === Transaction.METHOD_CASH}
                    empty
                    icon={CopyIcon}
                    onClick={() => copyToClipboard(getCurrentTransactionRef())}
                    reduced
                    rounded />
                </div>
              </div>
              <div className="payer-box">
                <span className="paid">Pagante</span>
                <UserCard user={currTr.transaction.getPayer()}
                  displayName={currSession.username === currTr.transaction.getPayer().getUsername() && 'Tú'}
                  mini
                  rounded />
              </div>
            </div>}
          </div>
        </div>}
      </div>
    </div>}
  </div>);
}

export default TransactionLineTime;