import Generic from "./Generic";
import Global from "./Global";

class Agreement extends Generic {
  /** The cancelled status */
  static get STATUS_CANCELLED() { return -2 }
  /** The suspended status */
  static get STATUS_SUSPENDED() { return -1 }
  /** The finalized status. */
  static get STATUS_FINALIZED() { return 0 }
  /** The active status. */
  static get STATUS_ACTIVE() { return 1 }
  /** The deadline today status */
  static get STATUS_CUT_TODAY() { return 2 }
  /** The near deadline status. */
  static get STATUS_CUT_NEAR() { return 3 }
  /** The delayed status */
  static get STATUS_DELAYED() { return 4 }

  /** @param {Agreement} [agreement] */
  constructor(agreement) {
    super(agreement);

    const {
      cancelationDate,
      endDate,
      files,
      hash,
      lessees,
      nextDeadline,
      startDate,
      status
    } = agreement ?? {}

    this.setCancellationDate(cancelationDate);
    this.setEndDate(endDate);
    /** @type {(import('./GenericFile').default&Blob)[]} */
    this.files = Array.isArray(files) ? [...files] : [];
    this.setHash(hash);
    this.setLessees(Array.isArray(lessees) ? [...lessees] : undefined);
    this.setNextDeadline(nextDeadline);
    this.setStartDate(startDate);
    this.setStatus(status);
  }

  /** Obtains the agreement's cancelation date. When defined, means the agreement's status is
   * cancelled */
  getCancellationDate() {
    return this.cancelationDate;
  }

  /** Assings a date of cancelation for the agreement. Value passes through Global.formatDateUTC
   * @param {number} cancelationDate
   */
  setCancellationDate(cancelationDate) {
    this.cancelationDate = Boolean(cancelationDate)
      ? Global.formatDateUTC(cancelationDate)
      : undefined;
  }

  /** Obtains the end date */
  getEndDate() {
    return this.endDate;
  }

  /** Assings an end date to the agreement. Value passes through Global.formatDateUTC
   * @param {number} endDate 
   */
  setEndDate(endDate) {
    this.endDate = Global.formatDateUTC(endDate);
  }

  /** Obtains the documents attached to the agreement. */
  getFiles() {
    return this.files;
  }

  /** Assings documents to this agreement.
   * @param {(import('./GenericFile').default&Blob)[]} files 
   */
  setFiles(files) {
    this.files = files ?? [];
  }

  /** Obtains the blockchain block's hash from where this agreement is located. */
  getHash() {
    return this.hash;
  }

  /** Assings a blockchain block's hash.
   * @param {string} hash 
   */
  setHash(hash) {
    this.hash = hash;
  }

  /** Obtains the lessees involved in this agreement. */
  getLessees() {
    return this.lessees;
  }

  /** Assings the lessees for the agreement.
   * @param {import('./User').default[]} lessees 
   */
  setLessees(lessees) {
    this.lessees = lessees ?? [];
  }

  /** Obtains the next deadline */
  getNextDeadline() {
    return this.nextDeadline;
  }

  /** Assings the next deadline. Value passes through Global.formatDateUTC
   * @param {number} nextDeadline 
   */
  setNextDeadline(nextDeadline) {
    this.nextDeadline = Global.formatDateUTC(nextDeadline);
  }

  /** Obtains the agreement's start date. */
  getStartDate() {
    return this.startDate;
  }

  /** Assings the agreement's start date.
   * @param {number} startDate 
   */
  setStartDate(startDate) {
    this.startDate = Global.formatDateUTC(startDate);
  }

  /** Obtains the agreement's status. */
  getStatus() {
    return this.status;
  }

  /** Assings an status to the agreement.
   * @param {-2|-1|0|1|2|3} status The current status, defined by class globals.
   */
  setStatus(status) {
    this.status = status;
  }

  /** Obtains the related class to the agreement's status. */
  statusToClass() {
    switch (this.getStatus()) {
      case Agreement.STATUS_ACTIVE: return 'active';
      case Agreement.STATUS_CANCELLED: return 'cancelled';
      case Agreement.STATUS_DELAYED: return 'delayed';
      case Agreement.STATUS_FINALIZED: return 'finalized';
      case Agreement.STATUS_SUSPENDED: return 'suspended';
      case Agreement.STATUS_CUT_NEAR: return 'cut-near';
      case Agreement.STATUS_CUT_TODAY: return 'cut-today';
      default: return undefined;
    }
  }
}

export default Agreement;