import './styles/about-us.css';
import { useContext, useEffect } from 'react';
import { globalContext } from './context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import Button from './components/Button';
import UIRender from './objects/UIRender';
import Global from './objects/Global';
import { AaronImg, AlfredHappyImg, KevinImg, VickyImg } from './assets/images';

const AboutUs = () => {
  // *** useContext ***
  const { setShowFooter, setSearchMethod } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();

  useEffect(() => {
    setSearchMethod();
    setShowFooter(true);
    UIRender.scrollTo();
  }, [setShowFooter, setSearchMethod]);

  return (<div className="about-us container">
    <div className="pet-container">
      <div className="pet" id='vicky-fox'>
        <img className='vicky-fox' src={VickyImg} alt="vicky-fox" />
        <h5>Vicky Fox</h5>
      </div>
      <div className="pet" id='alfred-bear'>
        <img className='alfred-bear' src={AlfredHappyImg} alt="alfred-bear" />
        <h5>Alfred Bear</h5>
      </div>
      <div className="pet" id='aaron-gator'>
        <img className='aaron-gator' src={AaronImg} alt="aaron-gator" />
        <h5>Aaron Gator</h5>
      </div>
      <div className="pet" id='kevin-coon'>
        <img className='kevin-coon' src={KevinImg} alt="kevin-coon" />
        <h5>Kevin Coon</h5>
      </div>
    </div>
    <div className="message">
      <h1>Nosotros</h1>
      <p className='phar'>
        Somos una pequeña empresa digital mexicana que ofrece servicios para la promoción
        de bienes inmuebles. Surgimos de la experiencia personal que nuestros clientes
        han ganado rentando sus propiedades durante mucho tiempo.
      </p>
      <h2>Nuestro propósito</h2>
      <p className='phar jump'>
        Queremos que la promoción de bienes inmuebles y el control de alquileres sea lo
        más sencillo posible. Por eso ofrecemos varios servicios para la promoción de
        bienes inmuebles, dos de ellos son la publicación de bienes inmuebles (con soporte
        de mapas) y nuestro Gestor de Contratos Digitales.
        <br /><br />
        Este gestor ayuda a nuestros clientes a tener sus contratos de arrendamiento en
        un solo sitio, siendo accesibles para ellos y sus arrendatarios en cualquier
        momento. Además, sus contratos de arrendamiento son completamente inmutables, pues
        utilizamos tecnología de blockchain para evitar que sean modificados una vez firmados.
      </p>
      <h2>Nuestros clientes</h2>
      <p className='phar'>
        Desde grandes empresas hoteleras e inmobiliarias, hasta personas físicas que tienen una
        casa, local, depa, habitación, entre otros, que desean vender o rentar. Nuestro
        servicio es gratis para cualquier persona, solo debes registrarte. También ofrecemos
        planes de suscripción para expandir tus límites y exprimir al máximo nuestros servicios.
      </p>
      <div className="flex-box m5 jc-center">
        <div className="child auto-width">
          <Button onClick={() => navigate(Global.PATH_LICENSE_MANAGEMENT)} value='Ver licencias' />
        </div>
        <div className="child auto-width">
          <Button onClick={() => navigate(Global.PATH_SIGNUP)} value='Registrarme' />
        </div>
      </div>
      <h2>El futuro de Cuntrat</h2>
      <p className='phar'>
        Por ahora, nuestro interés está en la república mexicana. Si conseguimos crecer lo suficiente,
        nos esforzaremos para expandir nuestros servicios fuera del país.
      </p>
      <br />
      {/* @deprecated Add wormClown info */}
    </div>
  </div>);
}

export default AboutUs;