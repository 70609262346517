import './styles/signup.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalContext } from './context/GlobalContext';
import Button from './components/Button';
import Dialog from './components/popups/Dialog';
import Global from './objects/Global';
import Inputbar from './components/Inputbar';
import LoadingPanel from './components/LoadingPanel';
import UIRender from './objects/UIRender';
import User from './objects/User';
import Selectbar from './components/Selectbar';
import DAOServ from './objects/DAOServ';
// Icons
import { CheckIcon, CloseIcon, EditIcon, HideIcon, HintIcon, InfoIcon, ShowIcon, WarningIcon } from './assets/images';
import ErrHandler from './objects/ErrHandler';
import Hintbox from './components/Hintbox';
import Persona from './objects/Persona';

/** PrefixObject typedef
 * @typedef {Object} PrefixObject
 * @property {string} code Country initials.
 * @property {string} dial_code Prefix code.
 * @property {string} name Country name.
 */

const Signup = () => {
  // *** useContext ***
  const { currSession, setSearchMethod, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const prefixes = useRef(/** @type {PrefixObject[]}  */([]));
  const rfcRollback = useRef(/** @type {{ current: (nV: string) => void }} */({}));
  const today = useRef(/** @type {number} */(undefined));
  const user = useRef(new User());
  // input and select refs.
  const compoRefs = useRef(/** @type {[React.MutableRefObject<Element>]} */([
    useRef(), // First name. 0
    useRef(), // Last name. 1
    useRef(), // Genre. 2
    useRef(), // Birthdate. 3
    useRef(), // RFC. 4
    useRef(), // Phone prefix. 5
    useRef(), // Phone number. 6
    useRef(), // Email. 7
    useRef(), // Username. 8
    useRef(), // Password. 9
    useRef() // Submit button. 10
  ]));
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [fetchingData, setFetchingData] = useState(true);
  const [lockRFC, setLockRFC] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [togglePassword, setTogglePassword] = useState(true);
  const [usernameAv, setUsernameAv] = useState();

  /** @type {React.MouseEventHandler} */
  const signupBtnClickHandler = async () => {
    if (disableSubmit) return;

    setDisableUI(true); // Disable UI.
    UIRender.blurFocus() // Blur focus.
    let accountCreated = false;

    // Send request to create account.
    await DAOServ.post('signup', { user: Global.purgeObject(user.current) }, 'JSON')
      .then(() => {
        accountCreated = true;

        // Prepare payload for login.
        const payload = {
          username: user.current.getUsername(),
          passcode: user.current.getPassword()
        };

        return DAOServ.post('login', payload, 'JSON');
      }).then(query => {
        localStorage.setItem('tst', query.tst); // Save TST.
        pushMessageHint({ message: 'Cuenta creada', type: 'complete' });
        setShowDialog(true);
      }).catch(err => {
        pushMessageHint({
          message: !accountCreated
            ? ErrHandler.parseError(err)
            : 'Cuenta creada. Inicia sesión manualmente',
          type: !accountCreated ? 'error' : 'warning'
        });

        if (accountCreated) setShowDialog(true);
        else setDisableUI(false);
      });
  }

  const getAgeHintboxMessage = () => {
    const userAge = user.current.getAge(today.current);

    if (userAge === undefined || userAge >= Persona.MIN_AGE_YEAR) {
      return 'Para publicar tus propiedades y ponerlas en arrendamiento, '
        + 'debes registrar tu RFC. No podrás cambiar este dato manualmente una vez'
        + ' lo hayas registrado.';
    } else {
      return 'Como eres menor edad, no podrás registrar tu RFC. No podrás crear'
        + ' propiedades hasta que seas mayor de edad y los arrendadores sabrán'
        + ' que eres menor de edad cuando te contactes con ellos.';
    }
  }

  const checkUsernameHandleOnClick = async () => {
    if (!user.current.getUsername() || usernameAv !== undefined) return;

    setDisableUI(true);

    await DAOServ.post('username_available', { username: user.current.getUsername() }, 'JSON')
      .then(query => {
        if (!query.ok)
          pushMessageHint({ message: 'Nombre de usuario no disponible', type: 'error' });

        setUsernameAv(query.ok);
      }).catch(err => {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      });

    setDisableUI(false);
  }

  /** @type {React.KeyboardEventHandler<HTMLInputElement>} */
  const inputHandleOnKeyDown = e => {
    if (e.key === 'Enter') {
      let index = compoRefs.current.findIndex(compo => compo.current === e.target);

      if (compoRefs.current[index]?.current.value) {
        if (index === 3 && lockRFC) index++;

        UIRender.setFocus(compoRefs.current[index + 1].current);
      }
    }
  }

  const requestUnlockSubmit = () => {
    const aux = user.current;

    const identity = aux.getName()
      && aux.getLastName()
      && aux.getGenre() !== undefined
      && aux.getBirthdate();
    const contactData = aux.getPhone()?.number
      && aux.getPhone()?.prefix
      && aux.getEmail();
    const userData = aux.getUsername()
      && aux.getPassword();

    setDisableSubmit(!identity || !contactData || !userData);
  }

  useEffect(() => {
    const fetchData = async () => {
      let forward = false;

      await fetch(Global.FETCH_PREFIXES)
        .then(data => data.json())
        .then(data => {
          user.current.setPhone({ number: user.current.getPhone()?.number, prefix: '+52', code: 'MX' });
          prefixes.current = Array.from(data);
          setFetchingData(false);
          forward = true;
        }).catch(() => {
          pushMessageHint({
            message: 'No se pudo obtener la lista de prefijos. Inténtalo más tarde',
            type: 'error'
          });

          navigate(-1, { replace: true });
        });

      if (!forward) return;

      await DAOServ.getCurrentDay()
        .then(data => today.current = data)
        .catch(() => {
          pushMessageHint({
            message: 'Ocurrió un problema interno. Inténtalo más tarde',
            type: 'error'
          });

          navigate(-1, { replace: true });
        })
    }
    if (currSession.sessionStatus) {
      if (currSession.sessionStatus === User.SESSION_ACT) {
        navigate(Global.PATH_HOME, { replace: true });
      } else {
        setSearchMethod(false);
        fetchData();
      }
    }
  }, [currSession, navigate, pushMessageHint, setSearchMethod]);

  useEffect(() => {
    UIRender.scrollTo();
  }, [])

  if (fetchingData) return (<LoadingPanel className='full-height' />);
  else return (
    <div className="container signup" id='signup'>
      <div className="box borderless" onKeyDown={inputHandleOnKeyDown}>
        <h2 className='title highlight'>Llena todos los campos.</h2>
        <h6 className='overset'><span className="required" />Hay campos obligatorios.</h6>
        <h4 className="overset">Tus datos personales.</h4>
        <div className="flex-box wrap">
          <div className='child m3'>
            <Inputbar reference={compoRefs.current[0]}
              onBlur={input => input?.replace(/(^\s+|\s+$)/g, '').replace(/\s+/g, ' ')}
              onChange={input => {
                user.current.setFirstName(input);
                user.current.setRFC(undefined);
                rfcRollback.current('');
                setLockRFC(!(user.current.getFirstName()
                  && user.current.getLastName()
                  && user.current.getBirthdate()));
                requestUnlockSubmit();
              }} disabled={disableUI}
              filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
              maxLength={Persona.NAME_MAX_LENGTH}
              minLength={Persona.NAME_MIN_LENGTH}
              placeholder={{ default: 'Nombre(s)' }}
              required
              textTransform='capitalize' />
          </div>
          <div className='child m3'>
            <Inputbar reference={compoRefs.current[1]}
              onBlur={input => input?.replace(/(^\s+|\s+$)/g, '').replace(/\s+/g, ' ')}
              onChange={input => {
                user.current.setLastName(input);
                user.current.setRFC(undefined);
                rfcRollback.current('');
                setLockRFC(!(user.current.getFirstName()
                  && user.current.getLastName()
                  && user.current.getBirthdate()));
                requestUnlockSubmit();
              }} disabled={disableUI}
              filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
              maxLength={Persona.NAME_MAX_LENGTH}
              minLength={Persona.NAME_MIN_LENGTH}
              placeholder={{ default: 'Apellido(s)' }}
              required
              textTransform='capitalize' />
          </div>
        </div>
        <div className="flex-box wrap">
          <div className='child m3'>
            <Selectbar reference={compoRefs.current[2]}
              options={[
                { displayValue: 'Hombre', value: Persona.GENRE_MALE },
                { displayValue: 'Mujer', value: Persona.GENRE_FEMALE },
                { displayValue: 'Otro o prefiero no decirlo', value: Persona.GENRE_UNSET }
              ]} disabled={disableUI}
              onChange={option => {
                user.current.setGenre(option);
                requestUnlockSubmit();
              }} placeholder='Género'
              required />
          </div>
          <div className="child m3">
            <Inputbar reference={compoRefs.current[3]}
              onChange={input => {
                user.current.setBirthdate(Global.formatDateUTC(input, -timezoneOffset));
                user.current.setRFC(undefined);
                rfcRollback.current('');
                setLockRFC(!(user.current.getFirstName()
                  && user.current.getLastName()
                  && user.current.getBirthdate()));
                requestUnlockSubmit();
              }} disabled={disableUI}
              isValid={input => {
                if (!Global.REGEXP_DATE.test(Global.transformDate(input))) return false;

                const currYear = new Date(today.current).getFullYear();
                const year = Number(input?.split('-')[0].replace(/^0+/, ''));

                return !isNaN(year) && year >= 1940 && currYear - year >= 14;
              }} placeholder={{ default: 'Fecha de nacimiento', onIsValidFail: 'Fecha inválida' }}
              required
              type='date' />
          </div>
        </div>
        <div className="flex-box wrap">
          <div className='child m3 auto-width'>
            <Inputbar reference={compoRefs.current[4]}
              onChange={input => {
                user.current.setRFC(input);
                requestUnlockSubmit();
              }} disabled={disableUI || lockRFC || user.current.getAge(today.current) < Persona.MIN_AGE_YEAR}
              filters={[{ regExp: Global.REGEXP_FILTER_ALL_SYMBOLS }]}
              forceChangeRef={rfcRollback}
              isValid={input => user.current.testRFC(input)}
              maxLength={13}
              minLength={13}
              placeholder={{
                default: 'RFC',
                onIsValidFail: 'RFC inválido o no coincide con tus datos',
                onMinLengthFail: 'El RFC debe contener 13 caracteres'
              }} textTransform='uppercase'
              width={270} />
          </div>
          <div className="child m3">
            <Hintbox icon={WarningIcon}
              message={getAgeHintboxMessage()}
              type='warning' />
          </div>
        </div>
        <div className="flex-box wrap">
          <div className="flex-box">
            <div className="child m3 auto-width">
              <Selectbar reference={compoRefs.current[5]}
                options={prefixes.current?.map(p => {
                  return { displayValue: `${p.name} (${p.dial_code})`, value: `${p.code}_${p.dial_code}` }
                })} defaultValue={'MX_+52'}
                disabled={disableUI}
                onChange={option => {
                  user.current.setPhone({
                    number: user.current.getPhone()?.number,
                    prefix: option?.split('_')[1]
                  });
                  requestUnlockSubmit();
                }} placeholder='Prefijo'
                required
                width={125} />
            </div>
            <div className='child m3'>
              <Inputbar reference={compoRefs.current[6]}
                onChange={input => {
                  user.current.setPhone({ number: input, prefix: user.current.getPhone()?.prefix });
                  requestUnlockSubmit();
                }} disabled={disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                inputMode='numeric'
                maxLength={10}
                minLength={10}
                placeholder={{
                  default: 'Número de teléfono',
                  onMinLengthFail: 'Debe ser de 10 dígitos'
                }} required />
            </div>
          </div>
          <div className='child m3'>
            <Inputbar reference={compoRefs.current[7]}
              onChange={input => {
                user.current.setEmail(input);
                requestUnlockSubmit();
              }} disabled={disableUI}
              filters={[{ regExp: Global.REGEXP_FILTER_EMAIL }]}
              inputMode='email'
              isValid={input => Global.REGEXP_EMAIL.test(input)
                && !input.includes('@racchome')
                && input.includes('@cuntrat')
              } maxLength={255}
              placeholder={{ default: 'Correo electrónico', onIsValidFail: 'Correo inválido' }}
              required
              textTransform='lowercase' />
          </div>
        </div>
        <h4 className="overset">Tus datos de usuario</h4>
        <div className='flex-box wrap'>
          <div className="child m3 auto-width">
            <Inputbar reference={compoRefs.current[8]}
              onChange={input => {
                user.current.setUsername(input);
                setUsernameAv(undefined);
                requestUnlockSubmit();
              }} disabled={disableUI}
              filters={[{ regExp: Global.REGEXP_FILTER_USERNAME }]}
              isValid={input => Global.REGEXP_USERNAME.test(input)
                && !Global.REGEXP_RACCHOME.test(input)
                && !Global.REGEXP_CUNTRAT.test(input)
              } maxLength={20}
              minLength={5}
              placeholder={{
                default: 'Nombre de usuario',
                onIsValidFail: 'Nombre de usuario inválido',
                onMinLengthFail: 'El nombre de usuario debe contener de 5 a 21 caracteres'
              }} required
              textTransform='lowercase'
              width={250} />
          </div>
          <div className="child">
            <Hintbox empty icon={InfoIcon}
              message={'Tu nombre de usuario es único. Debe contener solo letras y números. '
                + 'Puede contener un símbolo (_.-*), pero no debe estar en los extremos. '
                + 'Memorízalo porque lo necesitarás para iniciar sesión'} />
          </div>
        </div>
        <Button disabled={disableUI && usernameAv}
          empty
          icon={usernameAv
            ? CheckIcon
            : usernameAv === false
              ? CloseIcon
              : HintIcon}
          isWaiting={disableUI && usernameAv === undefined}
          onClick={checkUsernameHandleOnClick}
          onWaitValue='Verificando...'
          title='Verificar la disponibilidad del usuario'
          typeRender={usernameAv
            ? 'complete'
            : usernameAv === false
              ? 'error'
              : ''}
          value={usernameAv
            ? 'Usuario disponible'
            : usernameAv === false
              ? 'Usuario no disponible'
              : 'Comprobar usuario'} />
        <div className="flex-box wrap">
          <div className='child m3 auto-width'>
            <Inputbar reference={compoRefs.current[9]}
              onChange={input => {
                user.current.setPassword(input);
                requestUnlockSubmit();
              }} disabled={disableUI}
              filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
              isValid={input => !Global.REGEXP_RACCHOME.test(input) && !Global.REGEXP_CUNTRAT.test(input)}
              maxLength={30}
              minLength={8}
              placeholder={{
                default: 'Contraseña',
                onIsValidFail: 'Entrada inválida o contiene símbolos prohibidos',
                onMinLengthFail: 'La contraseña debe contener de 8 a 30 caracteres'
              }} required
              type={togglePassword ? 'password' : 'text'}
              width={250} />
          </div>
          <div className="child auto-width m3">
            <Button disabled={disableUI}
              borderless
              rounded
              empty
              icon={togglePassword ? ShowIcon : HideIcon}
              onClick={() => setTogglePassword(!togglePassword)}
              reduced />
          </div>
          <div className="child">
            <Hintbox empty icon={InfoIcon}
              message={'Tu contraseña debe tener de 8 a 30 caracteres. Puedes poner lo que quieras, pero'
                + ' te recomendamos hacer una combinación de letras, números y símbolos (@#$!%?&_.). Cualquier'
                + ' Símbolo diferente a los anteriores será rechazado.'} />
          </div>
        </div>
        <div className="flex-box m5">
          <div className="child">
            <Hintbox icon={InfoIcon}
              message={'Al hacer clic en el siguiente botón, aceptas nuestra Política de privacidad '
                + 'y nuestros Términos y condiciones de uso.'} />
          </div>
          <div className="child auto-width">
            <Button reference={compoRefs.current[10]}
              animated
              disabled={!usernameAv || disableSubmit}
              id='signup-btn'
              value='Registrarme'
              onClick={signupBtnClickHandler}
              onWaitValue='Registrando...'
              icon={EditIcon}
              isWaiting={usernameAv && !disableSubmit && disableUI}
              typeRender='complete' />
          </div>
        </div>
      </div>
      {showDialog && <Dialog confirmBtn={{ value: 'Continuar' }}
        message={'Tu cuenta fue creada, pero debes verificarla. Revisa la Bandeja de Entrada'
          + ' o la Bandeja de Correo no Deseado del correo que proporcionaste y verifica tu'
          + ' cuenta para poder recuperarla en caso de que la pierdas.'}
        onResolve={() => {
          navigate('/', { replace: true });
          UIRender.reloadPage();
        }} renderButtonsEmpty
        renderButtonsRounded />}
    </div>
  );
}


export default Signup;