import './styles/navbar.css';
import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalContext } from '../context/GlobalContext';
import Button from './Button';
import Global from '../objects/Global';
import User from '../objects/User';

const Navbar = () => {
  // *** useContext ***
  const {
    currSession,
    currSessionPicture,
    searchMethod,
    setShowProfileMenu,
    showProfileMenu,
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const logoCompo = useRef(/** @type {HTMLAnchorElement} */(undefined));
  const searchBarCurrInput = useRef(/** @type {string|undefined} */(undefined));
  const userImgCompo = useRef(/** @type {HTMLImageElement} */(undefined));

  const navbarHandleOnClick = e => {
    if (e.target === userImgCompo.current) {
      if (!showProfileMenu) setShowProfileMenu(!showProfileMenu);
    } else if (e.target === logoCompo.current) {
      e.preventDefault();
      navigate(currSession.sessionStatus === User.SESSION_ACT ? Global.PATH_HOME : '/', { replace: true });
    }
  }

  /** @type {React.FormEventHandler<HTMLFormElement>} */
  const formSubmitHandler = e => {
    e.preventDefault();
    searchMethod.action && searchMethod.action(searchBarCurrInput.current);
  }

  // ** Render **
  return (
    <div className="navbar" onClick={e => navbarHandleOnClick(e)}>
      <a ref={logoCompo} href="/" className="text-logo">Cuntrat</a>
      {searchMethod?.type === 'input' && <form className="search-form" onSubmit={formSubmitHandler}>
        <input type="text"
          className="search-bar"
          placeholder={searchMethod.placeholder || "Busca algo aquí..."}
          defaultValue={searchMethod.value}
          onChange={e => searchBarCurrInput.current = e.target.value} />
      </form>}
      {searchMethod?.type === 'button' && <div className="search-button-container">
        <Button animated
          borderless
          className='search-btn'
          empty
          icon={searchMethod.icon}
          onClick={searchMethod.action}
          rounded
          stopPropagation
          value={searchMethod.placeholder ?? 'Button'} />
      </div>}
      <img ref={userImgCompo} src={currSessionPicture} alt="profile-img" className="profile-img" />
    </div>
  );
}

export default Navbar;